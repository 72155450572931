a{
  text-decoration: none;
  color: inherit;
}
/* styles.scss */
.center-loader {
display: flex;
justify-content: center;
align-items: center;
height: 100vh;

}

.top-sticky{
  padding: 0.75rem;
  background-color: rgb(220 38 38);
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
}

// .top-sticky-center{
//   // padding: 0.75rem 16rem;
//   background-color: rgb(30 58 138);
//   padding: 0.75rem;
//   // background-color: rgb(220 38 38);
//   color: white;
//   font-size: 1.5rem;
//   font-weight: 700;
// }

.footer2{
  row-gap: 1rem;
  margin-top: 44rem;
  padding: 1rem;
  max-width: 48rem;
  column-gap: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  // width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.terms2{
  gap: 1rem;
  margin-bottom: 0.5rem;
  color: rgb(59 130 246);
}

.top-sticky-blue{
  padding: 0.75rem 16rem;
  background-color: rgb(30 58 138);
  color: rgb(202, 13, 13);
  font-size: 1rem;
  font-weight: 800;
  text-align: left;
}

.top-sticky-blue-xy{
  padding: 0.75rem 16rem;
  background-color: rgb(30 58 138);
  color: white;
  font-size: 1rem;
  font-weight: 800;
  text-align: left;
}


@media (max-width: 768px) {
  .top-sticky-blue-xy {
    text-align: left; /* Center-align the text */
    padding: 1rem 1rem; /* Adjust padding for smaller screens */
  }
}
.top-sticky-blue-test2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px; /* Fixed height */
  width: 100%; /* You can set a fixed width or use a percentage */
  // background: linear-gradient(to left, #000000, #003F89, #003F67);
  color: rgb(255, 255, 255);
  background-color: #1e3a8a;
  font-size: 1.1rem;
  font-weight: 800;
  text-align: center;
  padding-left: 16px;
}

@media (max-width: 768px) {
  .top-sticky-blue-test2 {
      height: 66px; /* Adjusted height for smaller screens */
      width: 100%; /* Ensure it stays 100% width */
  }
}


.header-logo {
 width: 94px;
 height: 50px;
  // max-height: 100%;
}




.top-sticky-blue-test {
  margin-left: -10px;
  padding: 0.75rem 16rem;
  background-color: rgb(255, 255, 255);
  color: white;
  height: 80px;
  font-size: 1rem;
  font-weight: 800;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.28);
}
@media (max-width: 767px) { /* Adjust the max-width value as needed */
.top-sticky-blue-test {
  padding: 0.75rem 1rem; /* Adjust the padding as needed */
  justify-content: center; /* Align content to the left */
}
}





.main-container{
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}

.main-container-5{
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}

.main-descrition-bg{
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: rgb(254 249 195)
}

.main-descrition{
  padding: 0.5rem 1rem;
  text-align: center;
}

.main-descrition-5{
  // padding: 0.5rem 1rem;
  text-align: center;
}
.main-descrition-5-5{

  text-align: center;
}

.topic-img{
  width: 80%;
  margin-left: 10%;
}

.topic-img-5{
  width: 80%;
  margin-left: 10%;
  margin-top: 0.5rem;
}

.topic-img-larger{
  width: 100%;
  margin-left: 0;
  margin-top: 0.5rem;
}

.topic-img-middle{
  width: 90%;
  margin-left: 5%;
  margin-top: 0.5rem;
}
.topic-img-middle-z{
  width: 80%;
  margin-left: 11%;
  margin-top: -6px;
}
.topic-img-middle-z2{
  width: 18%;
  margin-left: 11%;
  margin-top: -6px;
}

.topic-img-middle-test{
  width: 70%;
  margin-left: 15%;
  margin-top: 8px;
}

.main-des-title{
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 900;
  padding: 1rem 0;
  color: #1f2937;
}

.main-des-title-6{
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
  padding: 1rem 0;
  color: #1f2937;
}
.main-des-title-6-2{
  font-size: 1.9rem;
  line-height: 2.5rem;
  font-weight: 600;
  padding: 1rem 0;
  color: #1f2937;
}
.main-des-title-6-7{
  font-family: "Roboto", sans-serif;
  font-size: 1.7rem;
  
  font-weight: 900;
  padding: 1rem 0;
  color: #1f2937;
  margin-top: -14px;
}
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .main-des-title-60a {
  // margin-top: -0.1rem;
    font-size: 1.5rem; /* Reduce the font size for smaller screens */
    line-height: 1.2; /* Adjust the line height as needed */
  }
}
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .main-des-title-6-7 {
  // margin-top: -0.1rem;
    font-size: 1.5rem; /* Reduce the font size for smaller screens */
    line-height: 1.2; /* Adjust the line height as needed */
  }
}
.main-des-title-6-test{
  font-size: 1.9rem;
  line-height: 50px;
  margin-top: 5px;
  // font-weight: 700;
  // padding: 1rem 0;
  line-height: 1.3;
  color: #1f2937;
}

@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .main-des-title-6-test {
  // margin-top: -0.1rem;
    font-size: 1.5rem; /* Reduce the font size for smaller screens */
    line-height: 1.2; /* Adjust the line height as needed */
  }
}
.main-des-title-smaller{
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
  padding: 1rem 0;
  color: #1f2937;
}

.mian-des-1{
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0.5rem 0;
  text-align: center;
}

.mian-des-1-left{
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0.5rem 0;
  text-align: left;
}

.main-des-5{
  
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1rem 0;
  text-align: left;
  color: #1f2937;
}
.main-des-5-t{
  
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1rem 0;
  margin-top: -3px;
  text-align: left;
  color: #1f2937;
}

.main-des-2{
  color: rgb(220 38 38);
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 1rem 0;
}

.survey{
  padding: 1rem;
  // margin-top: 1rem;
}

.quiz{
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.quiz-5{
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  color: #1f2937;
}
.quiz-5-test{
  font-size: 1.999rem;
  line-height: 2.05rem;
  font-weight: 700;
  color: #1f2937;
}

.answer{
  display: grid;
  grid-template-columns: repeat(1,minmax(0,1fr));
  gap: 0.5rem;
  margin-top: 1.2rem;
  justify-items: center;
}

.answer-btn{
  color: white;
  letter-spacing: .025em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 1.5rem;
  background-color: rgb(21 128 61);
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 75%;
  cursor: pointer;
}

.answer-btn-5{
  color: white;
  letter-spacing: .025em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  padding: 0.75rem;
  background-color: rgb(34 197 94);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  cursor: pointer;
}
.answer-btn-5-test{
  color: white;
  letter-spacing: .025em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  padding: 0.75rem;
  background-color: rgb(18, 112, 255);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  cursor: pointer;
}

.ok{
  color: white;
    cursor: pointer;
  align-items: center;
  border-radius: 50px;
  width: 100%;
  background-color: rgb(18, 112, 255);
  display: flex;
  flex-direction: row;
  font-size: 30px;
  font-weight: 500;
  justify-content: center;
  line-height: 30px;
  margin: auto;
  padding: 14px 36px 14px 14px;

}

.ok-test{
  color: white;
    cursor: pointer;
  align-items: center;
  border-radius: 50px;
 height: 80px;
 width: 290px;
  background-color: rgb(18, 112, 255);
  display: flex;
  flex-direction: row;
  font-size: 30px;
  font-weight: 500;
  justify-content: center;
  line-height: 30px;
  margin: auto;
  // padding: 34px 36px 14px 14px;

}

.answer-btn:hover{
  background-color: rgb(34, 155, 78);
}

.answer-btn-5:hover{
  background-color: rgb(62, 233, 124);
}

.footer{
  row-gap: 1rem;
  margin-top: 44rem;
  padding: 1rem;
  max-width: 48rem;
  column-gap: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  // width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.terms{
  gap: 1rem;
  margin-bottom: 0.5rem;
  color: rgb(59 130 246);
}

.copyright{
  font-size: 12px;
}

.checking{
  text-align: center;
  padding: 1.5rem;
  background-color:#e9f2ff;
  border-top: 2px solid rgb(59 130 246);
  margin: 1rem;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #1f2937;
}

.congrats{
  font-size: 1.5rem;
  line-height: 2rem;
  color: rgb(34 197 94);
  font-weight: 700;
}

.congrats-false{
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(34 197 94);
  font-weight: 700;
  margin: 1rem 0;
}

.top-description{
  margin-top: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.top-description-5{
  margin-top: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  background-color: rgb(254 240 138);
}

.top-description-false{
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.spots-count{
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  background-color: rgb(253 224 71);
  margin: 1rem auto;
  border-radius: 0.5rem;
  width: max-content;
}

.tap-direction{
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-top: 2rem;
}

.tap-direction-span{
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-top: 2rem;
}

.call-btn{
  font-size: 1.5rem;
  line-height: 2rem;
  color: white;
  text-transform: capitalize;
  font-weight: 700;
  padding: 1.25rem;
  background-color: rgb(34 197 94);
  // width: 100%;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: 0.5rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / .25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
}

.call-btn:hover{
  background-color: rgb(59, 236, 124);
}

.call-btn-false{
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: black;
  text-transform: capitalize;
  font-weight: 700;
  padding: 1.25rem;
  background-color: rgb(234 179 8);
  // width: 100%;
  margin: 1rem 0;
  cursor: pointer;
}

.sub-title{
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  margin-top: 1.25rem;
}

.sub-title-span{
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin-top: 1.25rem;
}

.sub-description{
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1rem 0;
}

.sub-description-false{
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1rem;
}

.timer{
  font-size: 1.25rem;
  line-height: 1.75rem;
  background: transparent;
  padding: 0.25rem 1rem;
  color: red;
  border: 2px dashed red;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  display: flex;
  flex-direction: row;
}

.boxes{
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  gap: 50px;
  padding: 30px 0;
}

.visit-box{
  color: green;
  border: 2px green solid;
}

.view-box{
  color: red;
  border: 2px red solid;
}

.positive-box{
  color: tomato;
  border: 2px tomato solid;
}

.negative-box{
  color: blue;
  border: 2px blue solid;
}

.calls-box{
  color: rgb(14, 98, 146);
  border: 2px rgb(14, 98, 146) solid;
}

.box-container{
  border-radius: 15px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-size: 2.3rem;
  font-weight: 700;
}
.box-sup{
  font-size: 1.5rem;
  align-items: flex-start;
}

.box-sub{
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.box-sub-per{
  font-size: 1rem;
  color: rgb(16, 168, 16);
}

@media(max-width:768px){
  .sub-title{
    font-size: 1.125rem;
  }
  .sub-title-span{
    font-size: 0.8rem;
  }
  .call-btn{
    font-size: 1.35rem;
    line-height: 1.75rem;
  }
  .tap-direction{
    font-size: 1.1rem;
  }
  .tap-direction-span{
    font-size: 1rem;
  }
  .topic-img{
    width: 100%;
    margin-left: 0;
  }
  .boxes{
    grid-template-columns:repeat(1, 1fr);
    padding: 30px 40px;
    gap: 20px;
  }
  .top-sticky-blue{
    padding: 0.75rem;
  }
  // .top-sticky-center{
  //   padding: 0.75rem;
  // }
  .main-container-5{
    padding: 0.4rem 1rem;
  }
  .main-des-title{
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding:0;
  }
  .main-des-title-6{
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0;
  }
  .main-des-title-smaller{
    font-size: 0.9rem;
    line-height: 1.5rem;
    padding:0;
  }
  .main-des-5{
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
  }
  .quiz-5{
    font-size: 1.82rem;
    line-height: 2.5rem;
  }
  .survey{
    padding: 0;
  }
  .answer-btn-5{
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .checking{
    margin:1.25rem 1rem;
  }
  .congrats{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .top-description-5{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .sub-description{
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@keyframes glow {
  0% {
      box-shadow: 0 0 5px #34C759, 0 0 10px #34C759;
  }
  50% {
      box-shadow: 0 0 10px #34C759, 0 0 20px #34C759;
  }
  100% {
      box-shadow: 0 0 5px #34C759, 0 0 10px #34C759;
  }
}
.bold-green-toast {
font-weight: bold;
color: green;
}

.green-bold {
color: rgb(34, 197, 94);
font-weight: bold;
}
.call-btn {
-webkit-animation: glow 1.5s infinite alternate;
animation: glow 1.5s infinite alternate
}
